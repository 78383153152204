var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"page-title"},[_vm._v("Delivery Notes")]),(_vm.pageLoad)?[_c('Spinner')]:[_c('b-row',[(_vm.selectedSale)?_c('router-link',{attrs:{"to":{
					name: 'deliveryNoteDetails',
					params: { saleId: _vm.selectedSale.id },
				}}},[_c('b-button',{staticClass:"admin-users-component-add-new-inventory-button w-195 ml-2"},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v(" Add New")])],1):_vm._e(),(_vm.selectedSale)?_c('router-link',{attrs:{"to":{
					name: 'deliveryNoteHistory',
					params: { saleId: _vm.selectedSale.id },
				}}},[_c('b-button',{staticClass:"admin-users-component-add-new-inventory-button w-195 ml-2"},[_c('i',{staticClass:"fa fa-bars",attrs:{"aria-hidden":"true"}}),_vm._v(" Delivery Notes")])],1):_vm._e()],1),_c('b-row',{staticClass:"pr-20 pb-50"},[_c('DxDataGrid',{attrs:{"data-source":_vm.sales,"key-expr":"id","allow-column-reordering":true,"showBorders":true,"show-row-lines":true,"word-wrap-enabled":true},on:{"selection-changed":_vm.selectSale,"cell-prepared":_vm.onCellPrepared}},[_c('DxColumn',{attrs:{"data-field":"sale_invoice_no","caption":"Invoice No","fixed":true}}),_c('DxColumn',{attrs:{"data-field":"type","caption":"Type","alignment":"left"}}),_c('DxColumn',{attrs:{"data-field":"customer_name","caption":"Customer Name","alignment":"left"}}),_c('DxColumn',{attrs:{"data-field":"contact_no","caption":"Contact Number","alignment":"left"}}),_c('DxColumn',{attrs:{"data-field":"created_by","caption":"Created By","alignment":"left"}}),_c('DxColumn',{attrs:{"data-field":"created_at","caption":"Date","alignment":"left"}}),_c('DxColumn',{attrs:{"data-field":"delivery_notes","caption":"Delivery Notes","alignment":"left"}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxPaging',{attrs:{"enabled":true,"page-size":25}}),_c('DxPager',{attrs:{"show-navigation-buttons":true,"show-info":true,"info-text":"Page #{0}. Total: {1} ({2} items)"}})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }