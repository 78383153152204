const InventoryItemHistoryState = {
	items: [],
	loading: false,
	pageLoad: false,
	errors: [],
	itemName: null,
	store: null,
	selectedItem: null,
};

export default InventoryItemHistoryState;
