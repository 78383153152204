export const loading = state => state.loading;

export const pageLoad = state => state.pageLoad;

export const items = state => state.items;

export const errors = state => state.errors;

export const selectedItem = state => state.selectedItem;

export const itemName = state => state.itemName;

export const store = state => state.store;
