const InventoryState = {
	inventories: [],
	inventoriesTotalAvgCost: [],
	loading: false,
	pageLoad: false,
	errors: [],
	updateInventory: null,
};

export default InventoryState;
