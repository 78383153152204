const LocationState = {
	locations: [],
	loading: false,
	pageLoad: false,
	errors: [],
	updateLocation: null,
	activeLocations: [],
};

export default LocationState;
