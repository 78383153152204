export const salesData = state => state.salesData;

export const title = state => state.title;

export const loading = state => state.loading;

export const fetchingSales = state => state.fetchingSales;

export const yearlySales = state => state.yearlySales;

export const yearlySalesTitle = state => state.yearlySalesTitle;
