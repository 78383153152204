var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"page-title"},[_vm._v("Inventory History")]),_c('b-row',[_c('router-link',{attrs:{"to":"/inventory"}},[_c('b-button',{staticClass:"admin-users-component-add-new-inventory-button"},[_c('i',{staticClass:"fa fa-chevron-left",attrs:{"aria-hidden":"true"}}),_vm._v(" Back ")])],1)],1),(_vm.pageLoad)?[_c('Spinner')]:[_c('b-row',{staticClass:"justify-content-md-center"},[_c('h4',[_c('u',[_vm._v(_vm._s(_vm.itemName))])])]),_c('b-row',{staticClass:"justify-content-md-center"},[_c('h6',[_vm._v("("+_vm._s(_vm.store)+")")])]),_c('b-row',{staticClass:"mt-30"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Quantity")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Description")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Move Invoice")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Purchase Invoice")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Sale Invoice")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action Performer")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Created At")])])]),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('th',{staticClass:"text-left",attrs:{"scope":"row"}},[_vm._v(_vm._s(item.status))]),_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.description))]),_c('td',[_c('router-link',{attrs:{"to":{
									name: 'move-details',
									params: { id: item.move_id },
								},"target":"_blank"}},[_vm._v(" "+_vm._s(item.move_invoice_no)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
									name: 'purchase-details',
									params: { id: item.purchase_id },
								},"target":"_blank"}},[_vm._v(" "+_vm._s(item.purchased_invoice_no)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
									name: 'sale-details',
									params: { id: item.sale_id },
								},"target":"_blank"}},[_vm._v(" "+_vm._s(item.sale_invoice_no)+" ")])],1),_c('td',[_vm._v(_vm._s(item.action_performer))]),_c('td',[_vm._v(_vm._s(item.created_at))])])}),0)])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }