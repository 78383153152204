const ChartState = {
	salesData: [],
	title: 'Last 07 days Sale',
	loading: false,
	yearlySalesTitle: '',
	fetchingSales: false,
	yearlySales: [],
};

export default ChartState;
