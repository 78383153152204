import { render, staticRenderFns } from "./ViewSaleDetails.vue?vue&type=template&id=b717effa&"
import script from "./ViewSaleDetails.vue?vue&type=script&lang=js&"
export * from "./ViewSaleDetails.vue?vue&type=script&lang=js&"
import style0 from "./ViewSaleDetails.vue?vue&type=style&index=0&id=b717effa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports