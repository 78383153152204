<template>
	<div class="landingPage">
		<h1 class="page-title">Landing page</h1>
	</div>
</template>

<script>
export default {
	name: 'Landing',
};
</script>

<style lang="scss">
@import '../../styles/styles.scss';

.landingPage {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
}
</style>
