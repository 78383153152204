<template>
	<div>
		<h1 class="page-title">Sales Quotation Page</h1>
	</div>
</template>

<script>
export default {
	name: 'SalesQuotationPage',
};
</script>

<style lang="scss">
@import '../../../styles/styles.scss';
</style>
