var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"page-title"},[_vm._v("Customers Management")]),(_vm.pageLoad)?[_c('Spinner')]:[_c('b-row',[_c('router-link',{attrs:{"to":"/add-customer"}},[_c('b-button',{staticClass:"admin-users-component-add-new-customer-button"},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v(" Add Customer")])],1),(_vm.selectedCustomer)?_c('router-link',{attrs:{"to":{
					name: 'show-customer',
					params: { id: _vm.selectedCustomer.id },
				}}},[_c('b-button',{staticClass:"admin-users-component-add-new-inventory-button w-195 ml-2"},[_c('i',{staticClass:"fa fa-bars",attrs:{"aria-hidden":"true"}}),_vm._v(" Details")])],1):_vm._e(),(_vm.selectedCustomer)?_c('router-link',{attrs:{"to":{
					name: 'update-customer',
					params: { id: _vm.selectedCustomer.id },
				}}},[_c('b-button',{staticClass:"admin-users-component-add-new-inventory-button w-195 ml-2"},[_c('i',{staticClass:"fa fa-pencil",attrs:{"aria-hidden":"true"}}),_vm._v(" Update Customer")])],1):_vm._e(),(_vm.role === 'admin' && _vm.selectedCustomer)?_c('b-button',{staticClass:"admin-users-component-change-status-button ml-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.onDeleteHandler(_vm.selectedCustomer.id)}}},[_c('i',{staticClass:"fa fa-times",attrs:{"aria-hidden":"true"}}),_vm._v(" Remove ")]):_vm._e()],1),_c('b-row',{staticClass:"pr-20 pb-50"},[_c('DxDataGrid',{attrs:{"data-source":_vm.customers,"key-expr":"id","allow-column-reordering":true,"showBorders":true,"show-row-lines":true},on:{"selection-changed":_vm.selectCustomer,"cell-prepared":_vm.onCellPrepared}},[_c('DxColumn',{attrs:{"data-field":"name","fixed":true,"sort-order":"asc"}}),_c('DxColumn',{attrs:{"data-field":"country","alignment":"center"}}),_c('DxColumn',{attrs:{"data-field":"mobile_no_dubai","caption":"Dubai Contact"}}),_c('DxColumn',{attrs:{"data-field":"mobile_no_country","caption":"Country Contact"}}),_c('DxColumn',{attrs:{"data-field":"credit_amount","alignment":"center"}}),_c('DxColumn',{attrs:{"data-field":"trn","alignment":"center","caption":"Customer TRN"}}),_c('DxColumn',{attrs:{"data-field":"status","alignment":"center"}}),_c('DxSelection',{attrs:{"mode":"single"}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxPaging',{attrs:{"enabled":true,"page-size":25}}),_c('DxPager',{attrs:{"show-navigation-buttons":true,"show-info":true,"info-text":"Page #{0}. Total: {1} ({2} items)"}})],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }