const CustomerState = {
	customers: [],
	loading: false,
	pageLoad: false,
	errors: [],
	updateCustomer: null,
	countries: [],
	customerDetails: null,
};

export default CustomerState;
