const MoveItemsState = {
	items: [],
	loading: false,
	pageLoad: false,
	errors: [],
	selectedItem: null,
	itemMoveDetails: null,
};

export default MoveItemsState;
