<template>
	<div>
		<h1>Oops! page not found</h1>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
};
</script>

<style lang="less" scoped></style>
