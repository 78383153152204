<template>
	<b-modal v-model="modalShow" title="Add New Customer" id="modal-xl" size="xl" hide-footer>
		<form @submit.prevent="submitForm">
			<div class="mb-3 h-100p">
				<label for="name" class="form-label">Name:</label>
				<input v-model="formData.name" type="text" class="form-control" id="name" />
				<span class="invalid-feedback left-text" v-if="errors.name">
					<strong>{{ errors.name[0] }}</strong>
				</span>
			</div>

			<div class="mb-3 h-100p">
				<label for="mark" class="form-label">Mark:</label>
				<input v-model="formData.mark" type="text" class="form-control" id="mark" />
				<span class="invalid-feedback left-text" v-if="errors.mark">
					<strong>{{ errors.mark[0] }}</strong>
				</span>
			</div>

			<div class="mb-3 h-100p" v-if="countries.length > 0">
				<label for="changeCountry">Country:</label>
				<select class="form-control" id="changeCountry" v-model="formData.country" @change="onChangeHandler($event)">
					<option v-for="country in countries" :key="country.id" :value="country.value">
						{{ country.text }}
					</option>
				</select>
			</div>

			<div class="mb-3 h-100p">
				<label for="mobile_no_dubai" class="form-label">Dubai Contact No:</label>
				<input v-model="formData.mobile_no_dubai" type="text" class="form-control" id="mobile_no_dubai" />
				<span class="invalid-feedback left-text" v-if="errors.mobile_no_dubai">
					<strong>{{ errors.mobile_no_dubai[0] }}</strong>
				</span>
			</div>

			<div class="mb-3 h-100p">
				<label for="mobile_no_country" class="form-label">Country Contact No:</label>
				<input v-model="formData.mobile_no_country" type="text" class="form-control" id="mobile_no_country" />
				<span class="invalid-feedback left-text" v-if="errors.mobile_no_country">
					<strong>{{ errors.mobile_no_country[0] }}</strong>
				</span>
			</div>

			<b-button type="submit" variant="primary" class="mr-20p btn btn-success">Submit</b-button>
			<b-button @click="closeModal" variant="secondary" class="btn btn-danger">Close</b-button>
		</form>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'AddNewCustomerComponent',
	mounted() {
		// Clear the form data

		this.clearForm();
	},
	computed: {
		...mapGetters({
			countries: 'customer/countries',
			loading: 'customer/loading',
			errors: 'customer/errors',
		}),
	},
	data() {
		return {
			modalShow: false,
			formData: {
				name: '',
				mark: '',
				mobile_no_dubai: '',
				mobile_no_country: '',
				country: 'United Arab Emirates',
				redirect: false,
			},
		};
	},
	methods: {
		...mapActions({
			addNewCustomer: 'customer/addNewCustomer',
			fetchCustomers: 'customer/fetchCustomers',
			clearValidationErrors: 'customer/clearValidationErrors',
		}),
		async submitForm() {
			this.clearValidationErrors();
			await this.addNewCustomer(this.formData);
			if (this.errors.length === 0) {
				await this.fetchCustomers();
				this.closeModal();
			}
		},
		closeModal() {
			this.modalShow = false;
			this.clearForm();
		},
		clearForm() {
			this.clearValidationErrors();
			this.formData = {
				name: '',
				mark: '',
				mobile_no_dubai: '',
				mobile_no_country: '',
				country: 'United Arab Emirates',
				redirect: false,
			};
		},
		onChangeHandler(event) {
			this.formData.country = event.target.value;
		},
	},
};
</script>

<style lang="scss">
@import '../../../styles/styles.scss';

.h-100p {
	height: 100px !important;
}

.grid-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
}

.mr-20p {
	margin-right: 20px;
}

.grid-item {
	text-align: left;
	padding: 20px 10px;
}

.underline {
	text-decoration: underline;
}

.add-sale-page-wrapping-div {
	padding-bottom: 100px;
}

.alert-span {
	display: block !important;
	padding-top: 10px;
	color: red;
}

.invalid-feedback {
	display: block !important;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.875em;
	color: #dc3545;
}
</style>
